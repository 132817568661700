@font-face {
  font-family: "Akira";
  src: url("../../fonts/Akira.otf")
  format("truetype");
}

@font-face {
  font-family: "PoppinsR";
  src: url("../../fonts/Poppins-Regular.ttf")
  format("truetype");
}

@font-face {
  font-family: "PoppinsB";
  src: url("../../fonts/Poppins-SemiBold.ttf")
  format("truetype");
}

@font-face {
  font-family: "DrukCyr";
  src: url("../../fonts/DrukWideCyTTMedium.ttf")
  format("truetype");
}

/* Стили для скроллбара */
::-webkit-scrollbar {
  width: 10px; /* Ширина скроллбара */
  background-color: #000; /* Цвет фона скроллбара */
}

/* Стили для трека скроллбара (фон) */
::-webkit-scrollbar-track {
  background: #000; /* Цвет полоски скроллбара */

}

/* Стили для полоски скроллбара */
::-webkit-scrollbar-thumb {
  background: #182538; /* Цвет трека скроллбара */

  border-radius: 5px; /* Радиус закругления углов полоски скроллбара */
}

/* Стили для полоски скроллбара при наведении */

@keyframes float{
  0%,to{
    -webkit-transform:translateX(10px);
    transform:translateX(10px)
  }
  50%{
    -webkit-transform:translateY(10px);
    transform:translateY(10px)
  }
}


.just{
  justify-content: center;
  display: flex;
}

.just{
  display:flex;
  justify-content:center
}
*{
  box-sizing:border-box;
  margin:0;
  padding:0
}
a{
  text-decoration:none
}
@media screen and (max-width:400px){
  .mouse-tracker{
    display: none;
  }

  a{
    text-decoration:none
  }
  .ww2{
    padding-left:15%
  }
  .ww1,.ww2{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:30px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .ww1{
    margin-left:-10%
  }
  .main__backround{
    background:#1f252e;
    width:100%
  }
  .main__backround .dd{
    display:flex;
    justify-content:center
  }
  .main__backround .dd header{
    background:#182538;
    height:960px;
    width:100%
  }
  .main__backround .dd header nav{
    margin-top:31px;
    width:100%
  }
  .main__backround .dd header nav .nav__item{
    display:flex;
    width:100%
  }
  .main__backround .dd header nav .nav__item .ff{
    margin-left:5%;
    margin-right:25%;
    width:10%
  }
  .main__backround .dd header nav .nav__item .ff p{
    width:100%
  }
  .main__backround .dd header nav .nav__item .ff p .a{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:30px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal;
    margin-top:24px;
    width:50%
  }
  .main__backround .dd header nav .nav__item .launges{
    display:flex;
    width:100%
  }
  .main__backround .dd header nav .nav__item .launges .active{
    color:rgba(149,161,174,.8);
    font-family:Akira
  }
  .main__backround .dd header nav .nav__item .launges p{
    font-family:Akira;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .main__backround .dd header .header__item{
    margin-top:518px;
    width:100%
  }
  .main__backround .dd header .oblako1{
    display:none
  }
  .main__backround .dd header .imggg{
    position:absolute;
    top:-60px;
    width:100%
  }
  .main__backround .dd header .imggg .oblako2{
    flex-shrink:0;
    left:82px;
    position:absolute;
    top:188px;
    width:50%
  }
  .main__backround .dd header .imggg .programmer{
    flex-shrink:0;
    left:176px;
    position:absolute;
    top:216px;
    width:50%
  }
  .main__backround .dd header .imggg .pc{
    display:none;
    flex-shrink:0;
    left:22px;
    top:271px;
    width:90%
  }
  .main__backround .dd header .imggg .pce{
    display:block;
    flex-shrink:0;
    left:22px;
    position:absolute;
    top:271px;
    width:90%
  }
  .main__backround .dd header .imggg .Code1{
    left:20%;
    top:378px
  }
  .main__backround .dd header .imggg .Code1,.main__backround .dd header .imggg .Code2{
    display:none;
    flex-shrink:0;
    position:absolute;
    -webkit-transform:rotate(3.031deg);
    transform:rotate(3.031deg);
    width:40%
  }
  .main__backround .dd header .imggg .Code2{
    left:266px;
    top:529px
  }
  .main__backround .dd header .imggg .Mobile{
    flex-shrink:0;
    left:-50px;
    position:absolute;
    top:403px;
    width:60%
  }
  .main__backround .dd header .header__item{
    margin-left:10%;
    width:90%
  }
  .main__backround .dd header .header__item h1{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:33px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.225px;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:90%
  }
  .main__backround .dd header .header__item .text1{
    align-items:center;
    display:flex;
    gap:17.64px;
    margin-top:19px
  }
  .main__backround .dd header .header__item .text1 .circle{
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text1 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:20px;
    font-style:normal;
    font-weight:800;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:95%
  }
  .main__backround .dd header .header__item .text2{
    align-items:center;
    display:flex;
    gap:17.64px;
    margin-top:31px
  }
  .main__backround .dd header .header__item .text2 .circle{
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text2 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:20px;
    font-style:normal;
    font-weight:800;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:95%
  }
  .main__backround .dd header .header__item .text3{
    align-items:center;
    display:flex;
    gap:17.64px;
    margin-top:31px
  }
  .main__backround .dd header .header__item .text3 .circle{
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text3 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:20px;
    font-style:normal;
    font-weight:800;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:95%
  }
  .main__backround .dd header .contacts__ico{
    display:none
  }
  .main__backround .dd section{
    background:#182538;
    width:100%
  }
  .main__backround .dd section hr{
    stroke-width:1px;
    stroke:#95a1ae;
    flex-shrink:0;
    height:0;
    margin-left:5%;
    opacity:.3;
    width:90%
  }
  .main__backround .dd section .tt{
    display:flex;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .tt h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:150%;
    font-style:normal;
    font-weight:500;
    letter-spacing:.91px;
    line-height:normal;
    margin-top:7px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround .dd section .d-flexx .div1{
    background:#f7bb67;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:572px;
    margin-top:41px;
    width:100%
  }
  .main__backround .dd section .d-flexx .div2{
    background:#95a2ed;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:520px;
    margin-top:32px;
    width:100%
  }
  .main__backround .dd section .d-flexx .card1{
    background:#f7bb67;
    border-radius:50px;
    flex-shrink:0;
    height:572px;
    left:5%;
    position:absolute;
    top:1046px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card1 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card1 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-bottom:5%;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }
  .main__backround .dd section .d-flexx .card1 img{
    flex-shrink:0;
    margin-left:10%;
    width:90%
  }
  .main__backround .dd section .d-flexx .card1 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    margin-top:-57px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card1 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card1 button{
    background:#fff;
    border:0;
    border-radius:10px;
    color:#f7bb67;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    margin-top: 19%;
    position:absolute;
    width:35%;
    letter-spacing: 0.21px;
  }
  .main__backround .dd section .d-flexx .card1:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card1:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx .card2{
    background:#95a2ed;
    border-radius:50px;
    flex-shrink:0;
    height:520px;
    left:5%;
    position:absolute;
    top:1651px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card2 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card2 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-bottom:5%;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }

  .center {
    justify-content: center;
    display: flex;

    .main__backround .dd section .d-flexx .card2 img {
      flex-shrink: 0;
      margin-left: 5%;
      margin-top: -10%;
      width: 80%;
    }
  }
  .main__backround .dd section .d-flexx .card2 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card2 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card2 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#95a2ed;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    margin-top:4%;
    position:absolute;
    width:35%;
    letter-spacing: 0.21px;

  }
  .main__backround .dd section .d-flexx .card2:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card2:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx{
    display:block
  }
  .main__backround .dd section .d-flexx .div3{
    background:#800aa8;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:520px;
    margin-top:36px;
    width:100%
  }
  .main__backround .dd section .d-flexx .div4{
    background:#ffa622;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:572px;
    margin-top:35px;
    width:100%
  }
  .main__backround .dd section .d-flexx .card3{
    background:#800aa8;
    border-radius:50px;
    flex-shrink:0;
    height:520px;
    left:5%;
    position:absolute;
    top:2206px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card3 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card3 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-bottom:5%;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }
  .main__backround .dd section .d-flexx .card3 img{
    flex-shrink:0;
    margin-left:5%;
    margin-top:-10%;
    width:90%
  }
  .main__backround .dd section .d-flexx .card3 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:18px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card3 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card3 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#800aa8;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    margin-top:-7%;
    position:absolute;
    width:35%;
    letter-spacing: 0.21px;

  }
  .main__backround .dd section .d-flexx .card3:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card3:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx .card4{
    background:#ffa622;
    border-radius:50px;
    flex-shrink:0;
    height:572px;
    left:5%;
    position:absolute;
    top:2762px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card4 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card4 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }
  .main__backround .dd section .d-flexx .card4 img{
    flex-shrink:0;
    margin-left:5%;
    width:90%
  }
  .main__backround .dd section .d-flexx .card4 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    margin-top:-35px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card4 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card4 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#ffa622;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    margin-top:9%;
    position:absolute;
    width:36%;
    letter-spacing: 0.21px;
  }
  .main__backround .dd section .d-flexx .card4:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card4:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx .button__link:hover{
    border-radius:50px;
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .button__link:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .more{
    display:flex;
    justify-content:center;
    margin-top:25px
  }
  .main__backround .dd section .more h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:30px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal
  }
  .main__backround .dd section .moree{
    display:flex;
    justify-content:center;
    margin-bottom:138px;
    margin-top:-13px;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround .dd section .moree h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:30px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround .dd footer{
    background:#182538;
    width:100%
  }
  .main__backround .dd footer h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:45px;
    font-style:normal;
    font-weight:500;
    line-height:normal;
    margin-top:19px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround .d-flexxx{
    display:flex;
    gap:6%;
    margin-left:6%;
    margin-top:32px;
    width:90%
  }
  .main__backround .d-flexxx a .telegram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    height:132px;
    justify-content:center;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx a .telegram img{
    width:97px
  }
  .main__backround .d-flexxx .telegram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .d-flexxx a .instagram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:132px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx a .instagram img{
    width:94px
  }
  .main__backround .d-flexxx a .instagram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .d-flexxx a .discord{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:132px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx a .discord:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .d-flexxx a{
    width:20%
  }
  .main__backround .d-flexxx a .gmail{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:132px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx .gmail:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .nn{
    display:none
  }
  .main__backround .d-ff .Coder1,.main__backround .d-ff .Coder2{
    flex-shrink:0;
    width:100%
  }
  @-webkit-keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  @keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  .oblako2,.programmer{
    -webkit-animation:float 3.5s ease-in-out infinite;
    animation:float 3.5s ease-in-out infinite
  }
  .pc,.pce{
    -webkit-animation:float 3s ease-in-out infinite;
    animation:float 3s ease-in-out infinite
  }
  .Code1,.Code2,.Mobile{
    -webkit-animation:float 3.1s ease-in-out infinite;
    animation:float 3.1s ease-in-out infinite
  }
  .dd{
    display:flex;
    justify-content:center
  }
  .fade-down{
    width:100%
  }
}
@media screen and (min-width:401px)and (max-width:519px){
  .mouse-tracker{
    display: none;
  }

  a{
    text-decoration:none
  }
  .ww2{
    padding-left:15%
  }
  .ww1,.ww2{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:30px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .ww1{
    margin-left:-30%
  }
  .main__backround{
    background:#1f252e;
    width:100%
  }
  .main__backround .dd{
    display:flex;
    justify-content:center
  }
  .main__backround .dd header{
    background:#182538;
    height:1060px;
    width:100%
  }
  .main__backround .dd header nav{
    margin-top:31px;
    width:100%
  }
  .main__backround .dd header nav .nav__item{
    display:flex;
    width:100%
  }
  .main__backround .dd header nav .nav__item .ff{
    margin-left:10%;
    margin-right:35%;
    width:10%
  }
  .main__backround .dd header nav .nav__item .ff p{
    width:100%
  }
  .main__backround .dd header nav .nav__item .ff p .a{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:30px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal;
    margin-top:24px;
    width:50%
  }
  .main__backround .dd header nav .nav__item .launges{
    display:flex;
    width:100%
  }
  .main__backround .dd header nav .nav__item .launges .active{
    color:rgba(149,161,174,.8);
    font-family:Akira
  }
  .main__backround .dd header nav .nav__item .launges p{
    font-family:Akira;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .main__backround .dd header .header__item{
    margin-top:636px;
    width:100%
  }
  .main__backround .dd header .oblako1{
    display:none
  }
  .main__backround .dd header .imggg{
    position:absolute;
    top:-60px;
    width:100%
  }
  .main__backround .dd header .imggg .oblako2{
    flex-shrink:0;
    left:82px;
    position:absolute;
    top:188px;
    width:50%
  }
  .main__backround .dd header .imggg .programmer{
    flex-shrink:0;
    left:189px;
    position:absolute;
    top:216px;
    width:50%
  }
  .main__backround .dd header .imggg .pc{
    display:none;
    flex-shrink:0;
    left:22px;
    top:271px;
    width:90%
  }
  .main__backround .dd header .imggg .pce{
    display:block;
    flex-shrink:0;
    left:22px;
    position:absolute;
    top:271px;
    width:90%
  }
  .main__backround .dd header .imggg .Code1{
    left:20%;
    top:378px
  }
  .main__backround .dd header .imggg .Code1,.main__backround .dd header .imggg .Code2{
    display:none;
    flex-shrink:0;
    position:absolute;
    -webkit-transform:rotate(3.031deg);
    transform:rotate(3.031deg);
    width:40%
  }
  .main__backround .dd header .imggg .Code2{
    left:266px;
    top:529px
  }
  .main__backround .dd header .imggg .Mobile{
    flex-shrink:0;
    left:-50px;
    position:absolute;
    top:403px;
    width:60%
  }
  .main__backround .dd header .header__item{
    margin-left:10%;
    width:90%
  }
  .main__backround .dd header .header__item h1{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:33px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.225px;
    line-height:normal;
    width:90%
  }
  .main__backround .dd header .header__item .text1{
    align-items:center;
    display:flex;
    gap:17.64px;
    margin-top:19px
  }
  .main__backround .dd header .header__item .text1 .circle{
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text1 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:20px;
    font-style:normal;
    font-weight:800;
    line-height:normal;
    width:95%
  }
  .main__backround .dd header .header__item .text2{
    align-items:center;
    display:flex;
    gap:17.64px;
    margin-top:31px
  }
  .main__backround .dd header .header__item .text2 .circle{
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text2 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:20px;
    font-style:normal;
    font-weight:800;
    line-height:normal;
    width:95%
  }
  .main__backround .dd header .header__item .text3{
    align-items:center;
    display:flex;
    gap:17.64px;
    margin-top:31px
  }
  .main__backround .dd header .header__item .text3 .circle{
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text3 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:20px;
    font-style:normal;
    font-weight:800;
    line-height:normal;
    width:95%
  }
  .main__backround .dd header .contacts__ico{
    display:none
  }
  .main__backround .dd section{
    background:#182538;
    width:100%
  }
  .main__backround .dd section hr{
    stroke-width:1px;
    stroke:#95a1ae;
    flex-shrink:0;
    height:0;
    margin-left:5%;
    opacity:.3;
    width:90%
  }
  .main__backround .dd section .tt{
    display:flex;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .tt h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:150%;
    font-style:normal;
    font-weight:500;
    letter-spacing:.91px;
    line-height:normal;
    margin-top:7px;
    text-align:center
  }
  .main__backround .dd section .d-flexx .div1{
    background:#f7bb67;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:630px;
    margin-top:55px;
    width:100%
  }
  .main__backround .dd section .d-flexx .div2{
    background:#95a2ed;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:488px;
    margin-top:51px;
    width:100%
  }
  .main__backround .dd section .d-flexx .card1{
    background:#f7bb67;
    border-radius:50px;
    flex-shrink:0;
    height:630px;
    left:5%;
    position:absolute;
    top:1150px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card1 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card1 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-bottom:5%;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }
  .main__backround .dd section .d-flexx .card1 img{
    flex-shrink:0;
    margin-left:10%;
    width:90%
  }
  .main__backround .dd section .d-flexx .card1 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    margin-top:-57px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card1 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card1 button{
    margin-top: 9%;
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#f7bb67;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    position:absolute;
    width:35%
  }
  .main__backround .dd section .d-flexx .card1:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card1:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx .button__link:hover .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card2{
    background:#95a2ed;
    border-radius:50px;
    flex-shrink:0;
    height:488px;
    left:5%;
    position:absolute;
    top:1819px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card2 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card2 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-bottom:5%;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }

  .center {
    justify-content: center;
    display: flex;

    .main__backround .dd section .d-flexx .card2 img {
      width: 68%;
      flex-shrink: 0;
      margin-left: 5%;
      margin-top: -10%;
    }
  }
  .main__backround .dd section .d-flexx .card2 h3{
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:19px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card2 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card2 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#95a2ed;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    margin-top:-5%;
    position:absolute;
    width:35%
  }
  .main__backround .dd section .d-flexx .card2:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card2:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx{
    display:block
  }
  .main__backround .dd section .d-flexx .div3{
    background:#800aa8;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:520px;
    margin-top:23px;
    width:100%
  }
  .main__backround .dd section .d-flexx .div4{
    background:#ffa622;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:630px;
    margin-top:40px;
    width:100%
  }
  .main__backround .dd section .d-flexx .card3{
    background:#800aa8;
    border-radius:50px;
    flex-shrink:0;
    height:520px;
    left:5%;
    position:absolute;
    top:2348px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card3 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card3 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-bottom:5%;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }
  .main__backround .dd section .d-flexx .card3 img{
    flex-shrink:0;
    margin-left:26px;
    margin-top:-50px;
    width:335px
  }
  .main__backround .dd section .d-flexx .card3 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    margin-top:-4%;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card3 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card3 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#800a7a;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    margin-top:-5%;
    position:absolute;
    width:35%
  }
  .main__backround .dd section .d-flexx .card3:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card3:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx .card4{
    background:#ffa622;
    border-radius:50px;
    flex-shrink:0;
    height:630px;
    left:5%;
    position:absolute;
    top:2912px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card4 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card4 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }
  .main__backround .dd section .d-flexx .card4 img{
    flex-shrink:0;
    margin-left:5%;
    width:90%
  }
  .main__backround .dd section .d-flexx .card4 h3{
    margin-top: 20px;
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card4 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card4 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#ffa622;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    position:absolute;
    width:35%
  }
  .main__backround .dd section .d-flexx .card4:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card4:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx .button__link:hover{
    border-radius:50px;
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .button__link:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .more{
    display:flex;
    justify-content:center;
    margin-top:45px
  }
  .main__backround .dd section .more h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:35px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal
  }
  .main__backround .dd section .moree{
    display:flex;
    justify-content:center;
    margin-bottom:184px;
    margin-top:-10px
  }
  .main__backround .dd section .moree h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:35px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal
  }
  .main__backround .dd footer{
    background:#182538;
    width:100%
  }
  .main__backround .dd footer h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:45px;
    font-style:normal;
    font-weight:500;
    line-height:normal;
    margin-top:19px;
    text-align:center
  }
  .main__backround .d-flexxx{
    display:flex;
    gap:6%;
    margin-left:6%;
    margin-top:32px;
    width:90%
  }
  .main__backround .d-flexxx a .telegram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    height:132px;
    justify-content:center;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx a .telegram img{
    width:97px
  }
  .main__backround .d-flexxx .telegram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .d-flexxx a .instagram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:132px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx a .instagram img{
    width:94px
  }
  .main__backround .d-flexxx a .instagram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .d-flexxx a .discord{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:132px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx a .discord:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .d-flexxx a{
    width:20%
  }
  .main__backround .d-flexxx a .gmail{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:132px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx .gmail:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .nn{
    display:none
  }
  .main__backround .d-ff .Coder1,.main__backround .d-ff .Coder2{
    animation: float 3.1s ease-in-out infinite;
    flex-shrink:0;
    width:100%
  }
  @-webkit-keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  @keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  .oblako2,.programmer{
    -webkit-animation:float 3.5s ease-in-out infinite;
    animation:float 3.5s ease-in-out infinite
  }
  .pc,.pce{
    -webkit-animation:float 3s ease-in-out infinite;
    animation:float 3s ease-in-out infinite
  }
  .Code1,.Code2,.Mobile{
    -webkit-animation:float 3.1s ease-in-out infinite;
    animation:float 3.1s ease-in-out infinite
  }
  .dd{
    display:flex;
    justify-content:center
  }
  .fade-down{
    width:100%
  }
}
@media screen and (min-width:520px)and (max-width:640px){
  .mouse-tracker{
    display: none;
  }

  a{
    text-decoration:none
  }
  .ww2{
    padding-left:15%
  }
  .ww1,.ww2{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:30px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .main__backround{
    background:#1f252e;
    width:100%
  }
  .main__backround .dd{
    display:flex;
    justify-content:center
  }
  .main__backround .dd header{
    background:#182538;
    height:1060px;
    width:100%
  }
  .main__backround .dd header nav{
    margin-top:31px;
    width:100%
  }
  .main__backround .dd header nav .nav__item{
    display:flex;
    width:100%
  }
  .main__backround .dd header nav .nav__item .ff{
    margin-left:10%;
    margin-right:35%;
    width:10%
  }
  .main__backround .dd header nav .nav__item .ff p{
    width:100%
  }
  .main__backround .dd header nav .nav__item .ff p .a{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:30px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal;
    margin-top:24px;
    width:50%
  }
  .main__backround .dd header nav .nav__item .launges{
    display:flex;
    width:100%
  }
  .main__backround .dd header nav .nav__item .launges .active{
    color:rgba(149,161,174,.8);
    font-family:Akira
  }
  .main__backround .dd header nav .nav__item .launges p{
    font-family:Akira;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .main__backround .dd header .header__item{
    margin-top:636px;
    width:100%
  }
  .main__backround .dd header .oblako1{
    display:none
  }
  .main__backround .dd header .imggg{
    position:absolute;
    top:-60px;
    width:100%
  }
  .main__backround .dd header .imggg .oblako2{
    flex-shrink:0;
    left:82px;
    position:absolute;
    top:188px;
    width:50%
  }
  .main__backround .dd header .imggg .programmer{
    flex-shrink:0;
    left:200px;
    position:absolute;
    top:216px;
    width:50%
  }
  .main__backround .dd header .imggg .pc{
    display:none;
    flex-shrink:0;
    left:22px;
    top:271px;
    width:90%
  }
  .main__backround .dd header .imggg .pce{
    display:block;
    flex-shrink:0;
    left:22px;
    position:absolute;
    top:271px;
    width:90%
  }
  .main__backround .dd header .imggg .Code1{
    left:20%;
    top:378px
  }
  .main__backround .dd header .imggg .Code1,.main__backround .dd header .imggg .Code2{
    display:none;
    flex-shrink:0;
    position:absolute;
    -webkit-transform:rotate(3.031deg);
    transform:rotate(3.031deg);
    width:40%
  }
  .main__backround .dd header .imggg .Code2{
    left:266px;
    top:529px
  }
  .main__backround .dd header .imggg .Mobile{
    flex-shrink:0;
    left:-50px;
    position:absolute;
    top:403px;
    width:60%
  }
  .main__backround .dd header .header__item{
    margin-left:10%;
    width:90%
  }
  .main__backround .dd header .header__item h1{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:33px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.225px;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:90%
  }
  .main__backround .dd header .header__item .text1{
    align-items:center;
    display:flex;
    gap:17.64px;
    margin-top:19px
  }
  .main__backround .dd header .header__item .text1 .circle{
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text1 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:20px;
    font-style:normal;
    font-weight:800;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:95%
  }
  .main__backround .dd header .header__item .text2{
    align-items:center;
    display:flex;
    gap:17.64px;
    margin-top:31px
  }
  .main__backround .dd header .header__item .text2 .circle{
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text2 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:20px;
    font-style:normal;
    font-weight:800;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:95%
  }
  .main__backround .dd header .header__item .text3{
    align-items:center;
    display:flex;
    gap:17.64px;
    margin-top:31px
  }
  .main__backround .dd header .header__item .text3 .circle{
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text3 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:20px;
    font-style:normal;
    font-weight:800;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:95%
  }
  .main__backround .dd header .contacts__ico{
    display:none
  }
  .main__backround .dd section{
    background:#182538;
    width:100%
  }
  .main__backround .dd section hr{
    stroke-width:1px;
    stroke:#95a1ae;
    flex-shrink:0;
    height:0;
    margin-left:5%;
    opacity:.3;
    width:90%
  }
  .main__backround .dd section .tt{
    display:flex;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .tt h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:150%;
    font-style:normal;
    font-weight:500;
    letter-spacing:.91px;
    line-height:normal;
    margin-top:7px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround .dd section .d-flexx .div1{
    background:#f7bb67;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:720px;
    margin-top:55px;
    width:100%
  }
  .main__backround .dd section .d-flexx .div2{
    background:#95a2ed;
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    height:520px;
    margin-left:0;
    margin-top:30px;
    width:100%
  }
  .main__backround .dd section .d-flexx .card1{
    background:#f7bb67;
    border-radius:50px;
    flex-shrink:0;
    height:720px;
    left:5%;
    position:absolute;
    top:1150px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card1 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card1 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-bottom:5%;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }
  .main__backround .dd section .d-flexx .card1 img{
    flex-shrink:0;
    margin-left:10%;
    width:90%
  }
  .main__backround .dd section .d-flexx .card1 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    margin-top:-57px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card1 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card1 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#f7bb67;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    position:absolute;
    width:35%
  }
  .main__backround .dd section .d-flexx .card1:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card1:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx .card2{
    background:#95a2ed;
    border-radius:50px;
    flex-shrink:0;
    height:520px;
    left:5%;
    position:absolute;
    top:1912px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card2 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card2 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-bottom:5%;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }

  .center {
    justify-content: center;
    display: flex;

    img{
      width: 54%;
    }

    .main__backround .dd section .d-flexx .card2 img {
      flex-shrink: 0;
      margin-left: 5%;
      margin-top: -10%;
      width: 57%;
    }
  }
  .main__backround .dd section .d-flexx .card2 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:19px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card2 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card2 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#95a2ed;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    margin-top:-11%;
    position:absolute;
    width:35%
  }
  .main__backround .dd section .d-flexx .card2:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card2:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx{
    display:block
  }
  .main__backround .dd section .d-flexx .div3{
    background:#800aa8;
    height:520px
  }
  .main__backround .dd section .d-flexx .div3,.main__backround .dd section .d-flexx .div4{
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    margin-top:51px;
    width:100%
  }
  .main__backround .dd section .d-flexx .div4{
    background:#ffa622;
    height:720px
  }
  .main__backround .dd section .d-flexx .card3{
    background:#800aa8;
    border-radius:50px;
    flex-shrink:0;
    height:520px;
    left:5%;
    position:absolute;
    top:2490px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card3 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card3 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-bottom:5%;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }
  .main__backround .dd section .d-flexx .card3 img{
    flex-shrink:0;
    margin-left:86px;
    margin-top:-10%;
    width:335px
  }
  .main__backround .dd section .d-flexx .card3 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:19px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card3 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card3 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#800aa8;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    margin-top:-10%;
    position:absolute;
    width:35%
  }
  .main__backround .dd section .d-flexx .card3:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card3:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx .card4{
    background:#ffa622;
    border-radius:50px;
    flex-shrink:0;
    height:720px;
    left:5%;
    position:absolute;
    top:3075px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:90%
  }
  .main__backround .dd section .d-flexx .card4 .ddw{
    align-items:center;
    justify-content:center;
    width:100%
  }
  .main__backround .dd section .d-flexx .card4 .ddw h2{
    color:#fff;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-top:26px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase
  }
  .main__backround .dd section .d-flexx .card4 img{
    flex-shrink:0;
    margin-left:5%;
    width:90%
  }
  .main__backround .dd section .d-flexx .card4 h3{
    color:#fff;
    color:var(--White,#fff);
    flex-shrink:0;
    font-family:Microsoft Sans Serif;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:124.615px;
    line-height:30px;
    margin-top:-35px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:100%
  }
  .main__backround .dd section .d-flexx .card4 .dd1{
    display:none
  }
  .main__backround .dd section .d-flexx .card4 button{
    background:#fff;
    background:var(--White,#fff);
    border:0;
    border-radius:10px;
    color:#ffa622;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:58.626px;
    line-height:26px;
    margin-left:35%;
    position:absolute;
    width:35%
  }
  .main__backround .dd section .d-flexx .card4:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .card4:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .d-flexx .button__link:hover{
    border-radius:50px;
    transition:all .5s ease
  }
  .main__backround .dd section .d-flexx .button__link:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  .main__backround .dd section .more{
    display:flex;
    justify-content:center;
    margin-top:60px;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround .dd section .more h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:30px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround .dd section .moree{
    display:flex;
    justify-content:center;
    margin-bottom:184px;
    margin-top:-10px
  }
  .main__backround .dd section .moree h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:30px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal
  }
  .main__backround .dd footer{
    background:#182538;
    width:100%
  }
  .main__backround .dd footer h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:45px;
    font-style:normal;
    font-weight:500;
    line-height:normal;
    margin-top:19px;
    text-align:center
  }
  .main__backround .d-flexxx{
    display:flex;
    gap:6%;
    margin-left:6%;
    margin-top:32px;
    width:90%
  }
  .main__backround .d-flexxx a .telegram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    height:132px;
    justify-content:center;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx a .telegram img{
    width:97px
  }
  .main__backround .d-flexxx .telegram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .d-flexxx a .instagram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:132px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx a .instagram img{
    width:94px
  }
  .main__backround .d-flexxx a .instagram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .d-flexxx a .discord{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:132px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx a .discord:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .d-flexxx a{
    width:20%
  }
  .main__backround .d-flexxx a .gmail{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:132px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:100%
  }
  .main__backround .d-flexxx .gmail:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .nn{
    display:none
  }
  .main__backround .d-ff{
    margin-top:101px
  }
  .main__backround .d-ff .Coder1,.main__backround .d-ff .Coder2{
    animation: float 3.1s ease-in-out infinite;
    flex-shrink:0;
    width:100%
  }
  @-webkit-keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  @keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  .oblako2,.programmer{
    -webkit-animation:float 3.5s ease-in-out infinite;
    animation:float 3.5s ease-in-out infinite
  }
  .pc,.pce{
    -webkit-animation:float 3s ease-in-out infinite;
    animation:float 3s ease-in-out infinite
  }
  .Code1,.Code2,.Mobile{
    -webkit-animation:float 3.1s ease-in-out infinite;
    animation:float 3.1s ease-in-out infinite
  }
  .dd{
    display:flex;
    justify-content:center
  }
  .fade-down{
    width:100%
  }
}
@media screen and (min-width:641px)and (max-width:2920px){

  .mouse-tracker {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 5vmin;
    height: 5vmin;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    opacity: 0.8;
    transform: translate(-50%, -50%) translate(
                    var(--mouse-x),
                    var(--mouse-y) // calc(var(--mouse-x) * 1px),
      // calc(var(--mouse-y) * 1px)
    );
    pointer-events: none;
  }

  .main__backround{
    background:#1f252e;
    display:grid;
    place-items:center;
    width:100%
  }
  .main__backround header{
    background:#182538;
    flex-shrink:0;
    height:966px;
    width:1440px
  }
  .main__backround header nav{
    height:98px;
    width:100%
  }
  .main__backround header nav .nav__item{
    display:flex;
    padding:22px 0 0 61px
  }
  .main__backround header nav .nav__item p a{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .main__backround header nav .nav__item .launges{
    display:flex;
    gap:30px;
    margin-left:942px
  }
  .main__backround header nav .nav__item .launges .active{
    color:rgba(149,161,174,.8)
  }
  .main__backround header nav .nav__item .launges p{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .main__backround header .header__item{
    margin-left:155px;
    margin-top:189px
  }
  .main__backround header .header__item h1{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:2.575px;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround header .header__item .text1{
    display:flex;
    gap:17px;
    margin-top:21px
  }
  .main__backround header .header__item .text1 .circle{
    align-items:center;
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    justify-content:center;
    margin-top:7px;
    width:22.283px
  }
  .main__backround header .header__item .text1 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:25.997px;
    font-style:normal;
    font-weight:500;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround header .header__item .text2{
    display:flex;
    gap:20px;
    margin-top:22px
  }
  .main__backround header .header__item .text2 .circle{
    align-items:center;
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    justify-content:center;
    margin-top:7px;
    width:22.283px
  }
  .main__backround header .header__item .text2 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:25.997px;
    font-style:normal;
    font-weight:500;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround header .header__item .text3{
    display:flex;
    gap:20px;
    margin-top:22px
  }
  .main__backround header .header__item .text3 .circle{
    align-items:center;
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    justify-content:center;
    margin-top:7px;
    width:22.283px
  }
  .main__backround header .header__item .text3 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:25.997px;
    font-style:normal;
    font-weight:500;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  .main__backround header .contacts__ico{
    display:block;
    display:flex;
    margin-left:155px;
    margin-top:21px
  }
  .main__backround header .contacts__ico .telegram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    gap:2.372px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    position:absolute;
    transition:-webkit-transform .5s ease;
    transition:transform .5s ease;
    transition:transform .5s ease,-webkit-transform .5s ease;
    width:94.245px
  }
  .main__backround header .contacts__ico .telegram img{
    height:94px;
    width:97px
  }
  .main__backround header .contacts__ico .telegram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround header .contacts__ico .instagram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:2.372px;
    height:93.862px;
    justify-content:center;
    margin-left:110px;
    padding:4.652px;
    position:absolute;
    transition:all .5s ease;
    width:94.245px
  }
  .main__backround header .contacts__ico .instagram img{
    width:94px
  }
  .main__backround header .contacts__ico .instagram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround header .oblako1{
    display:block;
    margin-top:-60px
  }
  .main__backround header .oblako2{
    margin-left:283px;
    margin-top:-380px;
    position:absolute
  }
  .main__backround header .programmer{
    margin-left:423px;
    margin-top:-360px;
    position:absolute
  }
  .main__backround header .pc{
    margin-left:265px;
    margin-top:-313px;
    position:absolute
  }
  .main__backround header .Code1{
    margin-left:368px;
    margin-top:-160px;
    position:absolute
  }
  .main__backround header .Code2{
    margin-left:531px;
    margin-top:-40px;
    position:absolute
  }
  .main__backround header .Mobile{
    margin-left:155px;
    margin-top:-20px;
    position:absolute
  }
  section{
    background:#182538;
    display:table;
    flex-shrink:0;
    height:2009px;
    width:1440px
  }
  section hr{
    stroke:#000;
    height:1px;
    margin-left:161px;
    width:1115.26px
  }
  section .tt{
    display:flex;
    justify-content:center;
    width:1440px
  }
  section .tt h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:30px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.05px;
    line-height:normal;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  section .div1{
    background:#f7bb67;
    height:720px;
    margin-left:164px
  }
  section .div1,section .div2{
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    margin-top:187px;
    width:520px
  }
  section .div2{
    background:#95a2ed;
    height:520px;
    margin-left:79px
  }
  section .d-flexx{
    display:flex
  }
  section .d-flexx .card1{
    background:#f7bb67;
    border-radius:50px;
    flex-shrink:0;
    height:720px;
    margin-left:163px;
    margin-top:187px;
    position:absolute;
    top:1200px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:520px
  }
  section .d-flexx .card1 h2{
    font-family:Druk;
    font-size:17px;
    font-weight:300;
    letter-spacing:.97px;
    line-height:17px;
    margin-left:167px;
    margin-top:0;
    padding-top:26px;
    text-transform:lowercase;
    width:169px
  }
  section .d-flexx .card1 h2,section .d-flexx .card1 h3{
    color:#fff;
    font-style:normal;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  section .d-flexx .card1 h3{
    color:var(--white,#fff);
    flex-shrink:0;
    font-family:PoppinsR;
    font-size:20px;
    font-weight:100;
    height:124.615px;
    line-height:30px;
    margin-left:18px;
    margin-top:-90px;
    width:469px
  }
  section .d-flexx .card1 .button__link{
    margin-left:182px;
    margin-top:38px
  }
  section .d-flexx .card1 .button__link .dd1{
    color:#800a7a;
    flex-shrink:0;
    font-family:Akira;
    font-size:12px
  }
  section .d-flexx .card1 .button__link .dd1,section .d-flexx .card1 .button__link a button{
    background:#fff;
    background:var(--white,#fff);
    border:0;
    border-radius:10px;
    font-style:normal;
    font-weight:800;
    height:57.645px;
    line-height:26px;
    width:139.321px
  }
  section .d-flexx .card1 .button__link a button{
    color:#f7bb67;
    font-family:DrukCyr;
    font-size:11px;
    letter-spacing:1.25px;
    margin-top:-58px;
    position:absolute
  }
  section .d-flexx .card1 img{
    height:420px;
    margin-left:30px;
    margin-top:48px;
    width:475px
  }
  section .d-flexx .card1:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  section .d-flexx .card1:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .d-flexx .button__link:hover{
    border-radius:50px;
    transition:all .5s ease
  }
  section .d-flexx .button__link:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .d-flexx .card2{
    background:#95a2ed;
    border-radius:50px;
    flex-shrink:0;
    height:520px;
    margin-left:750px;
    margin-top:187px;
    position:absolute;
    top:1200px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:520px
  }
  section .d-flexx .card2 h2{
    color:#fff;
    flex-shrink:0;
    font-family:Druk;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-left:186px;
    margin-top:0;
    padding-top:23px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase;
    width:149px
  }

  .center {
    display: flex;
    justify-content: center;
    margin-top: 9%;

    section .d-flexx .card2 img {
      flex-shrink: 0;
      margin-left: 66px;
      width: 56%;
    }
  }
  section .d-flexx .card2 h3{
    color:#fff;
    color:var(--white,#fff);
    flex-shrink:0;
    font-family:PoppinsR;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:59px;
    line-height:30px;
    margin-left:18px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:469px
  }
  section .d-flexx .card2 .button__link{
    margin-left:182px;
    margin-top:31px
  }
  section .d-flexx .card2 .button__link .dd1{
    color:#800a7a;
    flex-shrink:0;
    font-family:Akira;
    font-size:12px;
    margin-top:58px
  }
  section .d-flexx .card2 .button__link .dd1,section .d-flexx .card2 .button__link a button{
    background:#fff;
    background:var(--white,#fff);
    border:0;
    border-radius:10px;
    font-style:normal;
    font-weight:800;
    height:57.645px;
    line-height:26px;
    width:139.321px
  }
  section .d-flexx .card2 .button__link a button{
    color:#95a2ed;
    font-family:DrukCyr;
    font-size:11px;
    letter-spacing:1.25px;
    margin-top:-58px;
    position:absolute
  }
  section .d-flexx .card2:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  section .d-flexx .card2:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .d-flexx .div3{
    background:#800aa8;
    height:520px;
    margin-left:156px;
    margin-top:68px
  }
  section .d-flexx .div3,section .d-flexx .div4{
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    width:520px
  }
  section .d-flexx .div4{

    background:#ffa622;
    height:720px;
    margin-left:80px;
    margin-top:-108px
  }
  section .d-flexx .card3{
    background:#800aa8;
    border-radius:50px;
    flex-shrink:0;
    height:520px;
    margin-left:167px;
    margin-top:68px;
    position:absolute;
    top:2100px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:520px
  }
  section .d-flexx .card3 h2{
    color:#fff;
    flex-shrink:0;
    font-family:Druk;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-left:186px;
    margin-top:0;
    padding-top:23px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase;
    width:149px
  }
  section .d-flexx .card3 img{
    flex-shrink:0;
    margin-left:66px;
    margin-top:-10px;
    width:335px
  }
  section .d-flexx .card3 h3{
    color:#fff;
    color:var(--white,#fff);
    flex-shrink:0;
    font-family:PoppinsR;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:59px;
    line-height:30px;
    margin-left:18px;
    margin-top:-10px;
    text-align:center;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    width:469px
  }
  section .d-flexx .card3 .button__link{
    margin-left:182px;
    margin-top:-20px
  }
  section .d-flexx .card3 .button__link .dd1{
    flex-shrink:0;
    font-family:Akira;
    margin-top:58px
  }
  section .d-flexx .card3 .button__link .dd1,section .d-flexx .card3 .button__link a button{
    background:#fff;
    background:var(--white,#fff);
    border:0;
    border-radius:10px;
    color:#800a7a;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:57.645px;
    line-height:26px;
    width:139.321px
  }
  section .d-flexx .card3 .button__link a button{
    font-family:DrukCyr;
    margin-top:-58px;
    position:absolute
  }
  section .d-flexx .card3:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  section .d-flexx .card3:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .d-flexx .card4{
    background:#ffa622;
    border-radius:50px;
    flex-shrink:0;
    height:720px;
    margin-left:750px;
    margin-top:-132px;
    position:absolute;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    top:2095px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:520px
  }
  section .d-flexx .card4 h2{
    font-family:Druk;
    font-size:17px;
    font-weight:300;
    letter-spacing:.97px;
    line-height:17px;
    margin-left:191px;
    padding-top:26px;
    text-shadow:2px 2px 4px rgba(0,0,0,.3);
    text-transform:lowercase;
    width:169px
  }
  section .d-flexx .card4 h2,section .d-flexx .card4 h3{
    color:#fff;
    font-style:normal;
    margin-top:0;
    text-align:center
  }
  section .d-flexx .card4 h3{
    color:var(--white,#fff);
    font-family:PoppinsR;
    font-size:20px;
    font-weight:400;
    line-height:30px;
    margin-left:18px;
    width:472px
  }
  section .d-flexx .card4 .button__link{
    margin-left:182px;
    margin-top:23px
  }
  section .d-flexx .card4 .button__link .dd1{
    color:#800a7a;
    font-family:Akira
  }
  section .d-flexx .card4 .button__link .dd1,section .d-flexx .card4 .button__link a button{
    background:#fff;
    background:var(--white,#fff);
    border:0;
    border-radius:10px;
    flex-shrink:0;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:57.645px;
    line-height:26px;
    width:139.321px
  }
  section .d-flexx .card4 .button__link a button{
    color:#ffa622;
    font-family:DrukCyr;
    margin-top:-58px;
    position:absolute
  }
  section .d-flexx .card4 img{
    flex-shrink: 0;
    height: 388px;
    margin-left: 48px;
    width: 417px;
  }
  section .d-flexx .card4:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  section .d-flexx .card4:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .more{
    display:flex;
    justify-content:center;
    margin-top:32px;
    width:1440px
  }
  section .more .all{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:37px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal
  }
  section .moree{
    display:flex;
    justify-content:center;
    width:1440px
  }
  section .moree,section .moree h3{
    text-shadow:2px 2px 4px rgba(0,0,0,.3)
  }
  section .moree h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:37px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal;
    margin-top:-24px;
    text-align:center
  }
  section hr{
    stroke-width:1px;
    stroke:#95a1ae;
    flex-shrink:0;
    height:0;
    margin-top:167px;
    width:1127px
  }
  footer{
    background:#182538;
    display:table;
    height:1121px;
    width:1440px
  }
  footer h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:60px;
    font-style:normal;
    font-weight:800;
    letter-spacing:2.05px;
    line-height:normal;
    margin-left:15px;
    margin-top:91px;
    text-align:center
  }
  footer .d-flexxx{
    display:flex;
    gap:15.7px;
    margin-left:508px;
    margin-top:23px
  }
  footer .d-flexxx .telegram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:93.273px
  }
  footer .d-flexxx .telegram img{
    height:94px;
    width:97px
  }
  footer .d-flexxx .telegram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  footer .d-flexxx .instagram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:93.273px
  }
  footer .d-flexxx .instagram img{
    width:94px
  }
  footer .d-flexxx .instagram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  footer .d-flexxx .discord{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:93.273px
  }
  footer .d-flexxx .discord:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  footer .d-flexxx .gmail{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:93.273px
  }
  footer .d-flexxx .gmail:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  footer .nn{
    display:block
  }
  footer .nn .text__about{
    align-items:center;
    margin-top:15px;
    width:1440px
  }
  footer .nn .text__about h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:16px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.1px;
    line-height:normal;
    text-align:center
  }
  footer .nn .text__about2{
    align-items:center;
    width:1440px
  }
  footer .nn .text__about2 h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:16px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.1px;
    line-height:normal;
    text-align:center
  }
  footer .nn .text__about3{
    align-items:center;
    width:1440px
  }
  footer .nn .text__about3 h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:16px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.1px;
    line-height:normal;
    text-align:center
  }
  footer .d-ff{
    margin-top: -65px;
    display:flex
  }
  footer .d-ff .Coder1{
    animation: float 3.1s ease-in-out infinite;
    display:flex;
    margin-left:70px
  }
  footer .d-ff .Coder2{
    -webkit-animation:float 3s ease-in-out infinite;
    height:550px;
    margin-left:133px;
    margin-top:45px;
    width:35%
  }
  path{
    stroke:#95a1ae;
    stroke-width:6;
    stroke-dasharray:6488;
    stroke-dashoffset:6488;
    -webkit-animation:draw 7s linear forwards;
    animation:draw 7s linear forwards
  }
  .Coder{
    -webkit-animation:float 3s ease-in-out infinite;
    animation:float 3s ease-in-out infinite;
    position:relative
  }
  @-webkit-keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  @keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  .imggg{
    margin-left:497px;
    margin-top:-436px;
    position:page
  }
  .imggg,.pc{
    -webkit-animation:float 3s ease-in-out infinite;
    animation:float 3s ease-in-out infinite
  }
  .pc{
    position:relative
  }
  .pce{
    display:none
  }
  .Code1,.Code2{
    -webkit-animation:float 3.2s ease-in-out infinite;
    animation:float 3.2s ease-in-out infinite;
    display:block;
    position:relative
  }
  .oblako1{
    -webkit-clip-path:polygon(-10% 25%,100% 28%,100% 70%,40% 100%);
    clip-path:polygon(-10% 25%,100% 28%,100% 70%,40% 100%)
  }
  .oblako1,.oblako2,.programmer{
    -webkit-animation:float 3.5s ease-in-out infinite;
    animation:float 3.5s ease-in-out infinite;
    position:relative
  }
  .programmer{
    -webkit-clip-path:polygon(-10% 25%,100% 28%,100% 70%,40% 100%);
    clip-path:polygon(-10% 25%,100% 28%,100% 70%,40% 100%)
  }
  .ww1{
    margin-right:30px
  }
  .ww1,.ww2{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .ww2{
    margin-right:0
  }
}
@media screen and (min-width:1280px)and (max-width:1469px){
  .df2 {
    gap: 10%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    p {
      font-size: 2.5vw;
      color: rgb(0, 0, 0);
      font-family: Open Sans;
      font-weight: 400;
      line-height: 22.5px;
      letter-spacing: 0;
      text-align: left;

      span {
        color: white;
      }

      .name {
        color: white;
      }
    }

    .design {
    }
  }


  .main__backround{
    background:#1f252e;
    width:100%
  }
  .main__backround .dd{
    width:100%
  }
  .main__backround .dd header{
    background:#182538;
    flex-shrink:0;
    height:966px;
    width:100%
  }
  .main__backround .dd header nav{
    height:98px;
    width:100%
  }
  .main__backround .dd header nav .nav__item{
    display:flex;
    padding:22px 0 0 61px
  }
  .main__backround .dd header nav .nav__item .ff{
    margin-right:60%
  }
  .main__backround .dd header nav .nav__item .ff p a{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal;
    margin-right:60%
  }
  .main__backround .dd header nav .nav__item .launges{
    display:flex;
    gap:30px;
    margin:0
  }
  .main__backround .dd header nav .nav__item .launges .active{
    color:rgba(149,161,174,.8)
  }
  .main__backround .dd header nav .nav__item .launges p{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .main__backround .dd header .header__item{
    margin-left:10%;
    margin-top:189px;
    width:80%
  }
  .main__backround .dd header .header__item h1{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:35px;
    font-style:normal;
    font-weight:800;
    letter-spacing:2.575px;
    line-height:normal
  }
  .main__backround .dd header .header__item .text1{
    display:flex;
    gap:17px;
    margin-top:21px
  }
  .main__backround .dd header .header__item .text1 .circle{
    align-items:center;
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    justify-content:center;
    margin-top:7px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text1 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:25.997px;
    font-style:normal;
    font-weight:500;
    line-height:normal
  }
  .main__backround .dd header .header__item .text2{
    display:flex;
    gap:20px;
    margin-top:22px
  }
  .main__backround .dd header .header__item .text2 .circle{
    align-items:center;
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    justify-content:center;
    margin-top:7px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text2 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:25.997px;
    font-style:normal;
    font-weight:500;
    line-height:normal
  }
  .main__backround .dd header .header__item .text3{
    display:flex;
    gap:20px;
    margin-top:22px
  }
  .main__backround .dd header .header__item .text3 .circle{
    align-items:center;
    background:#6fb2ff;
    border-radius:17640.695px;
    height:22.283px;
    justify-content:center;
    margin-top:7px;
    width:22.283px
  }
  .main__backround .dd header .header__item .text3 h2{
    color:#95a1ae;
    font-family:sans-serif;
    font-size:25.997px;
    font-style:normal;
    font-weight:500;
    line-height:normal
  }
  .main__backround .dd header .contacts__ico{
    display:block;
    display:flex;
    margin-left:10%;
    margin-top:21px
  }
  .main__backround .dd header .contacts__ico .telegram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    gap:2.372px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    position:absolute;
    transition:-webkit-transform .5s ease;
    transition:transform .5s ease;
    transition:transform .5s ease,-webkit-transform .5s ease;
    width:94.245px
  }
  .main__backround .dd header .contacts__ico .telegram img{
    height:94px;
    width:97px
  }
  .main__backround .dd header .contacts__ico .telegram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd header .contacts__ico .instagram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:2.372px;
    height:93.862px;
    justify-content:center;
    margin-left:110px;
    padding:4.652px;
    position:absolute;
    transition:all .5s ease;
    width:94.245px
  }
  .main__backround .dd header .contacts__ico .instagram img{
    width:94px
  }
  .main__backround .dd header .contacts__ico .instagram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  .main__backround .dd header .ii{
    width:80%
  }
  .main__backround .dd header .ii .oblako1{
    display:block;
    margin-top:-60px
  }
  .main__backround .dd header .ii .oblako2{
    margin-left:183px;
    margin-top:-380px;
    position:absolute
  }
  .main__backround .dd header .ii .programmer{
    margin-left:321px;
    margin-top:-360px;
    position:absolute
  }
  .main__backround .dd header .ii .pc{
    margin-left:10%;
    margin-top:-313px;
    position:absolute
  }
  .main__backround .dd header .ii .Code1{
    margin-left:28%;
    margin-top:-160px;
    position:absolute
  }
  .main__backround .dd header .ii .Code2{
    margin-left:58%;
    margin-top:-40px;
    position:absolute
  }
  .main__backround .dd header .ii .Mobile{
    margin-left:-5px;
    margin-top:-20px;
    position:absolute;
    width:43%
  }
  section{
    background:#182538;
    display:table;
    flex-shrink:0;
    height:2009px;
    width:100%
  }
  section hr{
    stroke:#000;
    height:1px;
    margin-left:5%
  }
  section .tt{
    display:flex;
    justify-content:center;
    width:100%
  }
  section .tt h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:30px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.05px;
    line-height:normal
  }
  section .div1{
    background:#f7bb67;
    height:720px;
    margin-left:8%
  }
  section .div1,section .div2{
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    margin-top:187px;
    width:520px
  }
  section .div2{
    background:#95a2ed;
    height:520px;
    margin-left:53%;
    position:absolute
  }
  section .d-flexx{
    display:flex
  }
  section .d-flexx .card1{
    animation: float 3.1s ease-in-out infinite;
    background:#f7bb67;
    border-radius:50px;
    flex-shrink:0;
    height:720px;
    margin-left:8%;
    margin-top:187px;
    position:absolute;
    top:1200px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:520px
  }
  section .d-flexx .card1 h2{
    color:#fff;
    font-family:Druk;
    font-size:17px;
    font-style:normal;
    font-weight:300;
    letter-spacing:.97px;
    line-height:17px;
    margin-left:167px;
    margin-top:0;
    padding-top:26px;
    text-align:center;
    text-transform:lowercase;
    width:169px
  }
  section .d-flexx .card1 h3{
    color:#fff;
    color:var(--white,#fff);
    flex-shrink:0;
    font-family:PoppinsR;
    font-size:20px;
    font-style:normal;
    font-weight:100;
    height:124.615px;
    line-height:30px;
    margin-left:18px;
    margin-top:-90px;
    text-align:center;
    width:469px
  }
  section .d-flexx .card1 .button__link{
    margin-left:182px;
    margin-top: 69px;
  }
  section .d-flexx .card1 .button__link .dd1{
    color:#800a7a
  }
  section .d-flexx .card1 .button__link .dd1,section .d-flexx .card1 .button__link a button{
    background:#fff;
    background:var(--white,#fff);
    border:0;
    border-radius:10px;
    flex-shrink:0;
    font-family:DrukCyr;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:57.645px;
    line-height:26px;
    width:139.321px
  }
  section .d-flexx .card1 .button__link a button{
    color:#f7bb67;
    margin-top:-58px;
    position:absolute
  }
  section .d-flexx .card1 img{
    height:420px;
    margin-left:30px;
    margin-top:48px;
    width:475px
  }
  section .d-flexx .card1:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  section .d-flexx .card1:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .d-flexx .button__link:hover{
    border-radius:50px;
    transition:all .5s ease
  }
  section .d-flexx .button__link:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .d-flexx .card2{
    animation: float 3.1s ease-in-out infinite;
    background:#95a2ed;
    border-radius:50px;
    flex-shrink:0;
    height:520px;
    margin-left:53%;
    margin-top:187px;
    position:absolute;
    top:1200px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:520px
  }
  section .d-flexx .card2 h2{
    color:#fff;
    flex-shrink:0;
    font-family:Druk;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-left:186px;
    margin-top:0;
    padding-top:23px;
    text-align:center;
    text-transform:lowercase;
    width:149px
  }
  section .d-flexx .card2 img{
    flex-shrink:0;
    margin-left:66px
  }
  section .d-flexx .card2 h3{
    color:#fff;
    color:var(--white,#fff);
    flex-shrink:0;
    font-family:PoppinsR;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:59px;
    line-height:30px;
    margin-left:18px;
    margin-top:-50px;
    text-align:center;
    width:469px
  }
  section .d-flexx .card2 .button__link{
    margin-left:182px;
    margin-top:31px
  }
  section .d-flexx .card2 .button__link .dd1{
    color:#800a7a;
    font-family:Akira
  }
  section .d-flexx .card2 .button__link .dd1,section .d-flexx .card2 .button__link a button{
    background:#fff;
    background:var(--white,#fff);
    border:0;
    border-radius:10px;
    flex-shrink:0;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:57.645px;
    line-height:26px;
    width:139.321px
  }
  section .d-flexx .card2 .button__link a button{
    color:#95a2ed;
    font-family:DrukCyr;
    margin-top:-58px;
    position:absolute
  }
  section .d-flexx .card2:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  section .d-flexx .card2:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .d-flexx .div3{
    background:#800aa8;
    height:520px;
    margin-left:8%;
    margin-top:68px
  }
  section .d-flexx .div3,section .d-flexx .div4{
    border-radius:50px;
    -webkit-filter:blur(45px);
    filter:blur(45px);
    flex-shrink:0;
    width:520px
  }
  section .d-flexx .div4{
    background:#ffa622;
    height:720px;
    margin-left:53%;
    margin-top:-132px;
    position:absolute
  }
  section .d-flexx .card3{
    animation: float 3.1s ease-in-out infinite;
    background:#800aa8;
    border-radius:50px;
    flex-shrink:0;
    height:520px;
    margin-left:8%;
    margin-top:68px;
    position:absolute;
    top:2100px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:520px
  }
  section .d-flexx .card3 h2{
    color:#fff;
    flex-shrink:0;
    font-family:Druk;
    font-size:17px;
    font-style:normal;
    font-weight:500;
    height:17px;
    letter-spacing:.17px;
    line-height:17px;
    margin-left:186px;
    margin-top:0;
    padding-top:23px;
    text-align:center;
    text-transform:lowercase;
    width:149px
  }
  section .d-flexx .card3 img{
    flex-shrink:0;
    margin-left:66px;
    margin-top:-10px;
    width:335px
  }
  section .d-flexx .card3 h3{
    color:#fff;
    color:var(--white,#fff);
    flex-shrink:0;
    font-family:PoppinsR;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    height:59px;
    line-height:30px;
    margin-left:18px;
    margin-top:-10px;
    text-align:center;
    width:469px
  }
  section .d-flexx .card3 .button__link{
    margin-left:182px;
    margin-top:-20px
  }
  section .d-flexx .card3 .button__link .dd1{
    color:#800a7a;
    flex-shrink:0;
    font-family:Akira;
    font-size:12px;
    margin-top:58px
  }
  section .d-flexx .card3 .button__link .dd1,section .d-flexx .card3 .button__link a button{
    background:#fff;
    background:var(--white,#fff);
    border:0;
    border-radius:10px;
    font-style:normal;
    font-weight:800;
    height:57.645px;
    line-height:26px;
    width:139.321px
  }
  section .d-flexx .card3 .button__link a button{
    color:#800aa8;
    font-family:DrukCyr;
    font-size:11px;
    letter-spacing:1.25px;
    margin-top:-58px;
    position:absolute
  }
  section .d-flexx .card3:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  section .d-flexx .card3:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .d-flexx .card4{
    animation: float 3.1s ease-in-out infinite;
    background:#ffa622;
    border-radius:50px;
    flex-shrink:0;
    height:720px;
    margin-left:53%;
    margin-top:-132px;
    position:absolute;
    top:2095px;
    transition:-webkit-transform .2s ease;
    transition:transform .2s ease;
    transition:transform .2s ease,-webkit-transform .2s ease;
    width:520px
  }
  section .d-flexx .card4 h2{
    font-family:Druk;
    font-size:17px;
    font-weight:300;
    letter-spacing:.97px;
    line-height:17px;
    margin-left:191px;
    padding-top:26px;
    text-transform:lowercase;
    width:169px
  }
  section .d-flexx .card4 h2,section .d-flexx .card4 h3{
    color:#fff;
    font-style:normal;
    margin-top:0;
    text-align:center
  }
  section .d-flexx .card4 h3{
    color:var(--white,#fff);
    font-family:PoppinsR;
    font-size:20px;
    font-weight:400;
    line-height:30px;
    margin-left:18px;
    width:472px
  }
  section .d-flexx .card4 .button__link{
    margin-left:182px;
    margin-top:14px
  }
  section .d-flexx .card4 .button__link .dd1{
    color:#800a7a;
    font-family:Akira
  }
  section .d-flexx .card4 .button__link .dd1,section .d-flexx .card4 .button__link a button{
    background:#fff;
    background:var(--white,#fff);
    border:0;
    border-radius:10px;
    flex-shrink:0;
    font-size:12px;
    font-style:normal;
    font-weight:800;
    height:57.645px;
    line-height:26px;
    width:139.321px
  }
  section .d-flexx .card4 .button__link a button{
    color:#ffa622;
    font-family:DrukCyr;
    margin-top:-58px;
    position:absolute
  }
  section .d-flexx .card4 img{
    flex-shrink:0;
    height:450px;
    margin-left:40px;
    width:450px
  }
  section .d-flexx .card4:hover{
    border-radius:50px;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  section .d-flexx .card4:hover .dd1{
    background:rgba(128,10,122,.5);
    border-radius:15px;
    -webkit-filter:blur(15px);
    filter:blur(15px);
    transition:all .8s ease
  }
  section .more{
    display:flex;
    justify-content:center;
    margin-top:32px;
    width:100%
  }
  section .more .all{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:30px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal
  }
  section .moree{
    display:flex;
    justify-content:center;
    width:100%
  }
  section .moree h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:30px;
    font-style:normal;
    font-weight:500;
    letter-spacing:1.05px;
    line-height:normal;
    margin-top:-15px;
    text-align:center
  }
  section hr{
    stroke-width:1px;
    stroke:#95a1ae;
    flex-shrink:0;
    height:0;
    margin-top:167px;
    width:90%
  }
  footer{
    background:#182538;
    display:table;
    height:1121px;
    width:100%
  }
  footer .center{
    text-align:center
  }
  footer .center h2{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:60px;
    font-style:normal;
    font-weight:800;
    letter-spacing:2.05px;
    line-height:normal;
    margin:91px 0 0;
    text-align:center;
    width:100%
  }
  footer .d-flexxx{
    display:flex;
    gap:15.7px;
    margin-left:35%;
    margin-top:23px
  }
  footer .d-flexxx .telegram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:93.273px
  }
  footer .d-flexxx .telegram img{
    height:94px;
    width:97px
  }
  footer .d-flexxx .telegram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  footer .d-flexxx .instagram{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:93.273px
  }
  footer .d-flexxx .instagram:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  footer .d-flexxx .discord{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:93.273px
  }
  footer .d-flexxx .discord:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  footer .d-flexxx .gmail{
    align-items:center;
    -webkit-backdrop-filter:blur(6.9787364006px);
    backdrop-filter:blur(6.9787364006px);
    background:linear-gradient(180deg,rgba(246,247,251,.1),rgba(233,235,240,.1));
    border-radius:27.915px;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    gap:4.652px;
    height:93.862px;
    justify-content:center;
    padding:4.652px;
    transition:all .5s ease;
    width:93.273px
  }
  footer .d-flexxx .gmail:hover{
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
    transition:all .5s ease
  }
  footer .nn{
    display:block
  }
  footer .nn .text__about{
    align-items:center;
    margin-top:15px;
    width:100%
  }
  footer .nn .text__about h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:16px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.1px;
    line-height:normal;
    text-align:center
  }
  footer .nn .text__about2{
    align-items:center;
    width:100%
  }
  footer .nn .text__about2 h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:16px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.1px;
    line-height:normal;
    text-align:center
  }
  footer .nn .text__about3{
    align-items:center;
    width:100%
  }
  footer .nn .text__about3 h3{
    color:#95a1ae;
    font-family:DrukCyr;
    font-size:16px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.1px;
    line-height:normal;
    text-align:center
  }
  footer .d-ff{
    display:flex;
    width:95%
  }
  footer .d-ff .Coder1{
    display:flex;
    margin-left:79px
  }
  footer .d-ff .Coder1,footer .d-ff .Coder2{
    -webkit-animation:float 3s ease-in-out infinite;
    animation:float 3s ease-in-out infinite
  }
  footer .d-ff .Coder2{
    height:50%;
    margin:0;
    width:46%
  }
  path{
    stroke:#95a1ae;
    stroke-width:6;
    stroke-dasharray:6488;
    stroke-dashoffset:6488;
    -webkit-animation:draw 7s linear forwards;
    animation:draw 7s linear forwards
  }
  .Coder{
    -webkit-animation:float 3s ease-in-out infinite;
    animation:float 3s ease-in-out infinite;
    position:relative
  }
  @-webkit-keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  @keyframes float{
    0%,to{
      -webkit-transform:translateX(10px);
      transform:translateX(10px)
    }
    50%{
      -webkit-transform:translateY(10px);
      transform:translateY(10px)
    }
  }
  .imggg{
    margin-left:497px;
    margin-top:-436px;
    position:page
  }
  .imggg,.pc{
    -webkit-animation:float 3s ease-in-out infinite;
    animation:float 3s ease-in-out infinite
  }
  .pc{
    position:relative
  }
  .pce{
    display:none
  }
  .Code1,.Code2{
    -webkit-animation:float 3.2s ease-in-out infinite;
    animation:float 3.2s ease-in-out infinite;
    display:block;
    position:relative
  }
  .oblako1{
    -webkit-clip-path:polygon(-10% 25%,100% 28%,100% 70%,40% 100%);
    clip-path:polygon(-10% 25%,100% 28%,100% 70%,40% 100%)
  }
  .oblako1,.oblako2,.programmer{
    -webkit-animation:float 3.5s ease-in-out infinite;
    animation:float 3.5s ease-in-out infinite;
    position:relative
  }
  .programmer{
    -webkit-clip-path:polygon(-10% 25%,100% 28%,100% 70%,40% 100%);
    clip-path:polygon(-10% 25%,100% 28%,100% 70%,40% 100%)
  }
  .ww1{
    margin-right:30px
  }
  .ww1,.ww2{
    color:hsla(0,0%,100%,.8);
    font-family:Akira;
    font-size:45px;
    font-style:normal;
    font-weight:800;
    letter-spacing:1.575px;
    line-height:normal
  }
  .ww2{
    margin-right:0
  }
}